
























import Component from "vue-class-component";
import Vue from "vue";
import { namespace } from "vuex-class";
import { TicketTemplate } from "@/models/ticket-template.model";

const ticketTemplatesNamespace = namespace("TicketTemplates");

@Component({})
export default class TicketTemplateDetail extends Vue {
  isLoading = false;

  @ticketTemplatesNamespace.Getter("current")
  ticketTemplate!: TicketTemplate;

  @ticketTemplatesNamespace.Action("fetchById")
  fetchTicketTemplate!: (id: string) => Promise<TicketTemplate>;

  @ticketTemplatesNamespace.Action("create")
  createTicketTemplate!: (
    ticketTemplate: TicketTemplate
  ) => Promise<TicketTemplate>;

  @ticketTemplatesNamespace.Action("updateById")
  updateTicketTemplate!: (
    ticketTemplate: TicketTemplate
  ) => Promise<TicketTemplate>;

  @ticketTemplatesNamespace.Action("deleteById")
  deleteTicketTemplate!: (id: string) => Promise<TicketTemplate>;

  get ticketTemplateId() {
    return this.$route.params.id as string;
  }

  async doFetch() {
    this.isLoading = true;
    this.fetchTicketTemplate(this.ticketTemplateId);
    this.isLoading = false;
  }

  async save() {
    this.isLoading = true;
    const isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    if (this.ticketTemplateId) {
      await this.updateTicketTemplate(this.ticketTemplate);
    } else {
      await this.createTicketTemplate(this.ticketTemplate);
    }

    this.$root.$bvToast.toast("Page settings saved!", {
      variant: "success",
    });
    this.isLoading = false;
  }

  async created() {
    this.isLoading = true;
    if (this.ticketTemplateId) {
      await this.doFetch();
    }
    this.isLoading = false;
  }
}
